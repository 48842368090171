import { render, staticRenderFns } from "./shipstores-form.vue?vue&type=template&id=6ce17e9c&scoped=true&"
import script from "./shipstores-form.vue?vue&type=script&lang=js&"
export * from "./shipstores-form.vue?vue&type=script&lang=js&"
import style0 from "./shipstores-form.vue?vue&type=style&index=0&id=6ce17e9c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ce17e9c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VCol,VContainer,VDialog,VFileInput,VRow,VSpacer,VToolbar,VToolbarTitle})


/* hot reload */
if (module.hot) {
  var api = require("D:\\asyhub-workspace\\asyhub-platform-ui\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6ce17e9c')) {
      api.createRecord('6ce17e9c', component.options)
    } else {
      api.reload('6ce17e9c', component.options)
    }
    module.hot.accept("./shipstores-form.vue?vue&type=template&id=6ce17e9c&scoped=true&", function () {
      api.rerender('6ce17e9c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/cargo/pages/portcalls/shipstores/shipstores-form.vue"
export default component.exports