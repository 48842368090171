var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "asyent-card",
        {
          staticClass: "fill-height",
          attrs: { title: _vm.title, expandable: "" },
          scopedSlots: _vm._u(
            [
              !_vm.readonly && !_vm.isDisabledFunc(_vm.name) && !_vm.noNew
                ? {
                    key: "toolbar-actions",
                    fn: function () {
                      return [
                        _c("csv-import", {
                          attrs: {
                            "dialog-title": _vm.ae$lng("Upload"),
                            "map-fields": _vm.mapItems,
                            content: _vm.value2,
                            unique: true,
                            key1: "nameOfArticle",
                          },
                          on: {
                            save: function (r) {
                              return _vm.updateImportItemsList(r, _vm.value2)
                            },
                          },
                        }),
                        _c("asyent-button", {
                          attrs: {
                            id: "v-step-items",
                            "button-icon": "mdi-plus",
                            "button-tooltip": _vm.ae$lng(
                              "Add Ship Stores Item"
                            ),
                            icon: _vm.$vuetify.breakpoint.smAndDown,
                            text: !_vm.$vuetify.breakpoint.smAndDown,
                          },
                          on: { confirmed: _vm.openForNew },
                        }),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("AsyentSimpleTable", {
            attrs: {
              headers: _vm.itemHeadings,
              "items-list": _vm.value2,
              "is-disabled-func": _vm.isDisabledFunc,
              "edit-function": _vm.openForEdit,
              "delete-function": _vm.deleteSelected,
              "sort-by": "itemNumber",
              "sort-desc": false,
              name: "shipStoresItem.table",
            },
          }),
          _c("br"),
          _c("br"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "500px",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  !_vm.isNew && !_vm.noReset
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-reload",
                          "button-tooltip": _vm.ae$lng("Reset"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.resetItem },
                      })
                    : _vm._e(),
                  !_vm.isNew && !_vm.noDelete
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-delete",
                          "button-tooltip": _vm.ae$lng("Delete"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.deleteItem },
                      })
                    : _vm._e(),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-check",
                      "button-tooltip": _vm.ae$lng("Save"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.saveItem },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$lng("Cancel"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.closeDialog },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "ma-0 pa-0",
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp
                      ? "500px"
                      : "",
                  },
                },
                [
                  _c(
                    "ValidationObserver",
                    { ref: _vm.internalFormReference, attrs: { disabled: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.formValid,
                            callback: function ($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("nameOfArticle"),
                                          name: "shipStoresItem.nameOfArticle",
                                        },
                                        model: {
                                          value: _vm.itemData["nameOfArticle"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "nameOfArticle",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['nameOfArticle']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("quantity"),
                                          name: "shipStoresItem.quantity",
                                        },
                                        model: {
                                          value: _vm.itemData["quantity"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "quantity",
                                              $$v
                                            )
                                          },
                                          expression: "itemData['quantity']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("unit"),
                                          name: "shipStoresItem.unit",
                                        },
                                        model: {
                                          value: _vm.itemData["unit"],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.itemData, "unit", $$v)
                                          },
                                          expression: "itemData['unit']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("locationOnBoard"),
                                          name: "shipStoresItem.locationOnBoard",
                                        },
                                        model: {
                                          value:
                                            _vm.itemData["locationOnBoard"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "locationOnBoard",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['locationOnBoard']",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }